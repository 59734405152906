<script setup>
import { object, string } from 'yup'
import { motionFadeUp1500, motionFadeUp1600 } from '~/motions/fadeUp.js'

defineProps({
  background: {
    type: String,
    // default: 'https://double100.oss-ap-southeast-1.aliyuncs.com/contact_us_bg.png',
    default: '/images/contact_us_bg.png',
  },
})

const emit = defineEmits(['postData'])

const schema = object({
  name: string().required('Required'),
  email: string().email('Invalid email').required('Required'),
  companyName: string().required('Required'),
  whatsApp: string(),
  detail: string(),
})

const state = reactive({
  name: undefined,
  email: undefined,
  companyName: undefined,
  whatsApp: undefined,
  detail: undefined,
})

async function onSubmit(event) {
  emit('postData', event.data)
}
</script>

<template>
  <div :style="{ 'background-image': `url(${background})` }" class="flex items-center bg-cover bg-center bg-no-repeat">
    <div class="container mx-9 lg:mx-auto grid grid-cols-1 lg:grid-cols-2 items-center my-5 lg:my-14">
      <div class="hidden lg:block text-white">
        <h1
          v-motion
          :initial="{
            opacity: 0,
            y: -100,
          }"
          :enter="{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1500,
            },
          }" class="mb-20 text-4xl font-medium"
        >
          CONTACT US
        </h1>
        <h3 v-motion="motionFadeUp1500" class="text-2xl">
          Interested?
        </h3>
        <h3 v-motion="motionFadeUp1600" class="text-2xl">
          Contact us for a detailed offer.
        </h3>
      </div>
      <u-card class="lg:w-4/5">
        <u-form :schema="schema" :state="state" class="space-y-4" @submit="onSubmit">
          <u-form-group name="name" size="xl" required>
            <u-input v-model="state.name" :placeholder="`*${$t('Name')}`" />
          </u-form-group>
          <u-form-group name="email" size="xl" required>
            <u-input v-model="state.email" :placeholder="`*${$t('Email')}`" />
          </u-form-group>
          <u-form-group name="companyName" size="xl" required>
            <u-input v-model="state.companyName" :placeholder="`*${$t('Company Name')}`" />
          </u-form-group>
          <u-form-group name="whatsApp" size="xl">
            <u-input v-model="state.whatsApp" :placeholder="`${$t('WhatsApp')}`" />
          </u-form-group>
          <u-form-group name="detail" size="xl">
            <u-textarea v-model="state.detail" type="textarea" size="xl" :rows="3" autoresize resize :placeholder="`${$t('Detail')}`" />
          </u-form-group>
          <div class="flex justify-center">
            <u-button type="submit" class="bg-gradient-to-r from-prussian to-rosewood rounded px-9 lg:px-12">
              {{ $t('Send') }}
            </u-button>
          </div>
        </u-form>
      </u-card>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
