export const motionFadeUp1500 = {
  initial: {
    opacity: 0,
    y: 200,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1500,
    },
  },
}

export const motionFadeUp1600 = {
  initial: {
    opacity: 0,
    y: 200,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1600,
    },
  },
}
